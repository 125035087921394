import { inject, Injectable } from '@angular/core';
import { OffsetPagingOptions, PagingWrapper } from '@clover/core/helpers/paging';
import { HttpService } from '@clover/core/services/http.service';
import { UserService } from '@clover/core/services/user.service';
import { Observable } from 'rxjs';
import { DeleteProductFromWorkList, IProduct, ProductSortFields } from './products.entities';
import ProductWorklist from '@clover/products/models/product-worklist';
import { AssociatedCompany } from '@clover/network-v2/network/accounts/state/accounts.entities';
import { SearchCompaniesOptions } from '@clover/network-v2/network/lists/company-lists.service';
import { ICompany } from '@clover/product-v2/state/connected-companies/connected-companies.entities';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);

  getProducts(
    companyId: number,
    query: string,
    pagingOptions?: OffsetPagingOptions<ProductSortFields>,
    workListId?: number,
  ): Observable<PagingWrapper<IProduct>> {
    const searchParams = new URLSearchParams({
      by: pagingOptions?.orderBy || 'name',
      order: pagingOptions?.order || 'asc',
      offset: String(pagingOptions?.offset || 0),
      limit: String(pagingOptions?.limit || 20),
      query,
    });

    if (workListId) {
      searchParams.set('workListId', String(workListId));
    }

    return this.http.postV2<PagingWrapper<IProduct>>(
      `/api/companies/${companyId}/products/search?${searchParams.toString()}`,
      null,
    );
  }

  getWorklists(): Observable<ProductWorklist[]> {
    return this.http.getV2<ProductWorklist[]>(`api/companies/my/productWorklists`);
  }

  removeFromWorklist(worklistId: number, productIds: number[]): Observable<DeleteProductFromWorkList> {
    const productIdsData = { deletedProductIds: productIds };
    return this.http.patchV2<DeleteProductFromWorkList>(
      `api/companies/my/productWorklists/${worklistId}`,
      productIdsData,
    );
  }

  searchCompanies(
    options: SearchCompaniesOptions = { offset: 0, limit: 10, excludedIds: [], query: 'Test' },
  ): Observable<PagingWrapper<ICompany>> {
    return this.http.postV2<PagingWrapper<ICompany>>(
      `api/search/companies?offset=${options.offset || 0}&limit=${options.limit || 20}`,
      {
        companiesIdsToExclude: options.excludedIds || [],
        name: options.query || 'Test',
        includeOnlyConnected: true,
      },
    );
  }
}
